import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';
import { Hero, ReadLink, Title, Subtitle } from '../components/blog';

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        date
        lead
      }
      timeToRead
      body
    }
  }
`;

const Content = styled('div')`
  background-color: #fff;
  border: 1px solid #ececec;
  box-shadow: 2px 5px 25px 5px rgba(0, 0, 0, 0.1);
  margin: 0 -1rem;
  max-width: 920px;
  padding: 2rem 1rem;

  &::before,
  &::after {
    color: rgba(0, 0, 0, 0.54);
    content: '•••';
    display: block;
    letter-spacing: 0.75rem;
    text-align: center;
  }

  &::before {
    margin-bottom: 2rem;
  }

  &::after {
    margin-top: 2rem;
  }

  p {
    color: rgba(0, 0, 0, 0.84);
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 2rem;
  }

  @media (min-width: 768px) {
    margin: 0 auto;
    padding: 4rem 6rem;
  }
`;

const PostHeader = styled('p')`
  font-size: 0.75rem;
  margin-top: 1rem;
`;

const PostTemplate = ({ data: { mdx: post } }) => (
  <Layout fullWidth={true}>
    <Hero>
      <Title>{post.frontmatter.title}</Title>
      <Subtitle>{post.frontmatter.lead}</Subtitle>
      <PostHeader>
        {post.frontmatter.date} • {post.timeToRead} min read
      </PostHeader>
      <span>
        <ReadLink to="/blog">&larr; back to all posts</ReadLink>
      </span>
    </Hero>
    <Content>
      <MDXRenderer>{post.body}</MDXRenderer>
    </Content>
  </Layout>
);

export default PostTemplate;
